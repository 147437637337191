@import "bootstrap/scss/mixins/breakpoints";
@import "../../../../variables";
@import "../../../../utilities/shapes";
@import "../../../../components/headers";

.sliderTitle {
    @include h3();
}

.productCarousel {
    max-width: 1540px;
    width: calc(100vw - 260px);
    margin: 60px auto;

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        h2 {
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }

    .view-all {
        @include h5();

        color: $slate !important;
        text-transform: uppercase;
        text-decoration-line: underline;
        margin-top: 10px;
    }

    ul {
        padding-inline-start: 0;
    }

    .slick-list {
        padding: 0 5px;
    }

    .slick-slider {
        margin-bottom: 0;
    }

    .product-tile-slider .product-tile-column {
        padding: 10px;
        margin: 0;
    }

    .product-slider {
        height: 520px;

        .slider-prev {
            left: -80px;
        }

        .slider-next {
            right: -80px;
        }
    }

    .product-tile {
        padding: 25px;
    }

    .product-tile-buttons {
        margin-top: 10px;
        margin-bottom: 0;
        gap: 10px;
        flex-direction: column;

        $productTileButtonsMinWidth: 1553px;

        @media (min-width: $productTileButtonsMinWidth) {
            flex-direction: row;
        }

        > * {
            margin: 0;
            flex: 2;
            flex-basis: content;
            width: 100%;
        }
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        padding: 0 20px;
        margin: 40px auto;

        header {
            margin-bottom: 10px;

            a {
                display: none;
            }
        }

        .slick-list {
            padding: 0 20% 0 5px;
        }

        .slider-arrow {
            display: none !important;
        }

        .product-tile {
            padding: 20px;
        }

        .product-tile-buttons {
            flex-direction: row;
        }
    }
}
